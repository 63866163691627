import React, { Component } from "react";
import { Button, Table } from "react-bootstrap";

//import { Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Spinner } from "./Spinner";
import { ErrorMessage } from "./ErrorMessage";

import SafeMode from "./SafeMode";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

class ListUsers extends Component {
  state = {
    selectedUser: null,
    loading: true,
    error: null,
    showInfoModal: false,
    showSafeModeModal: false,
    users: []
  };

  hideInfo = () => {
    this.setState({ showInfoModal: false });
  };

  showUserInfo = (user, e) => {
    e.preventDefault();
    this.props.history.push(`/user/${user.Id}`);
    //this.setState({ selectedUser: user, showInfoModal: true });
  };

  hideSafeMode = () => {
    this.setState({ showSafeModeModal: false });
  };

  showSafeMode = user => {
    this.setState({ selectedUser: user, showSafeModeModal: true });
  };

  loadUsers = () => {
    API.graphql(graphqlOperation(queries.GetUsersInfo))
      .then(response => {
        if ("data" in response && "GetUsersInfo" in response.data) {
          this.setState({ loading: false, users: response.data.GetUsersInfo });
        } else {
          throw new Error("Cannot load users.");
        }
      })
      .catch(err => {
        console.log(err.errors);
      });
  };

  removeUser = user => {
    if (!window.confirm("Are you sure?")) {
      return;
    }

    API.graphql(graphqlOperation(mutations.RemoveUser, { id: user.Id }))
      .then(response => {
        if (!response["data"]["RemoveUser"]) {
          throw Error("Error while removing the user");
        }
        this.loadUsers();
      })
      .catch(err => {
        this.setState({ error: JSON.stringify(err) });
      });
  };

  componentDidMount() {
    this.loadUsers();
  }

  render() {
    if (this.state.error !== null) return <ErrorMessage message={this.state.error.toString()} />;

    if (this.state.loading)
      return (
        <div className="m-3 text-center">
          <Spinner />
        </div>
      );

    if (this.state.users && this.state.users.length > 0) {
      return (
        <div className="m-2">
          {this.state.showSafeModeModal && <SafeMode hideSafeMode={this.hideSafeMode} user={this.state.selectedUser} />}
          <Table bordered responsive hover size="md" variant="dark">
            <thead>
              <tr>
                <th>Id</th>
                <th>Email</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Is Registered?</th>
                <th>fnc()</th>
              </tr>
            </thead>
            <tbody>
              {this.state.users.map((user, key) => (
                <tr
                  className="mt-3"
                  style={{ cursor: "pointer" }}
                  key={key}
                  onClick={e => {
                    this.showUserInfo(user, e);
                  }}
                >
                  <td>{user.Id}</td>
                  <td>{user.Email}</td>
                  <td>{user.FName}</td>
                  <td>{user.LName}</td>
                  <td>{user.CognitoId && "Yes"}</td>

                  <td>
                    <Button
                      variant="danger"
                      onClick={e => {
                        e.stopPropagation();
                        this.removeUser(user);
                      }}
                      size="sm"
                    >
                      remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    }
    return <ErrorMessage message="User list is empty." />;
  }
}

export default ListUsers;
