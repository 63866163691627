import React, { Component } from "react";
//import { Spinner } from "./Spinner";
import { streams } from "@paperspace/client-sdk";
import { API, graphqlOperation } from "aws-amplify";
import { Modal } from "react-bootstrap";

class SafeMode extends Component {
  state = {
    show: true
  };
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    API.graphql(graphqlOperation(`mutation { CloudComputerAccessKey(Id: "${this.props.user.Id}") }`))
      .then(response => {
        console.log(response);
        var parsed = JSON.parse(response["data"]["CloudComputerAccessKey"]);
        const vmInfo = {
          accessToken: parsed["accessTokenId"],
          machineId: parsed["delegation"]["machine"]["ids"][0]
        };
        streams
          .createStream(this.myRef.current, vmInfo)
          .then(stream => {
            stream.open();
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentWillUnmount() {}

  render() {
    return (
      <Modal
        show={this.state.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          this.setState({ show: false });
        }}
        dialogClassName="modal-90w"
        className="modal-safe-mode"
      >
        <Modal.Body>
          <div className="iframe-container">
            <iframe title="safe_mode" ref={this.myRef} allowFullScreen></iframe>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SafeMode;
