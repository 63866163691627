import React, { Component } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Trans } from "react-i18next";
import { Row, Col, Badge, Button } from "react-bootstrap";
import FormatBytes from "./FormatBytes";

import { Spinner } from "./Spinner";
import PowerManagement from "./PowerManagement";
import { ErrorMessage } from "./ErrorMessage";

import * as queries from "../graphql/queries";

class UserDetails extends Component {
  state = {
    user: null,
    loading: true,
    error: null
  };

  editUser = (user, e) => {
    e.preventDefault();
    this.props.history.push(`/edit-user/${user.Id}`);
  };

  loadUser = id => {
    API.graphql(graphqlOperation(queries.FindUser, { id: id, withComputerInfo: true }))
      .then(({ data }) => {
        let userData, errorData;
        if (data && "FindUser" in data) {
          userData = data.FindUser;
        } else {
          errorData = "Cannot load user data.";
        }

        this.setState({
          loading: false,
          user: userData,
          error: errorData
        });
      })
      .catch(dataWithErrors => {
        this.setState({
          loading: false,
          user: dataWithErrors.data.FindUser,
          error: JSON.stringify(dataWithErrors.errors)
        });
      });
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    if (params.id) {
      this.loadUser(params.id);
    }
  }

  render() {
    if (this.state.loading)
      return (
        <div className="m-3 text-center">
          <Spinner />
        </div>
      );

    if (this.state.user) {
      return (
        <div className="box-shadow m-4" style={{ backgroundColor: "#fff", padding: "1rem" }}>
          {this.state.error &&
            <ErrorMessage message={this.state.error.toString()} />
          }
          <Row className="mt-3">
            <Col xs={10}>
              <h3 className="float-left">
                {this.state.user["FName"] + " " + this.state.user["LName"]} &lt;{this.state.user["Email"]}&gt;
              </h3>
            </Col>
            <Col xs={2}>
              <div className="float-right">
                <Button
                  variant="primary"
                  onClick={e => {
                    this.editUser(this.state.user, e);
                  }}
                >
                  Edit
                </Button>
              </div>
            </Col>
          </Row>

          <h4 className="mt-4">
            <Trans>TITLE_PERSONAL_DETAILS</Trans>
          </h4>

          <Row className="mt-3">
            <Col xs={2}>Description:</Col>
            <Col>
              <pre>{this.state.user["Description"]}</pre>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={2}>Id:</Col> <Col>{this.state.user["Id"]}</Col>
          </Row>
          <Row>
            <Col xs={2}>E-mail:</Col> <Col>{this.state.user["Email"]}</Col>
          </Row>
          <Row>
            <Col xs={2}>Cognito Id:</Col> <Col>{this.state.user["CognitoId"]}</Col>
          </Row>
          <Row>
            <Col xs={2}>HWID:</Col> <Col>{this.state.user["HWID"]}</Col>
          </Row>
          <Row>
            <Col xs={2}>Machine Id:</Col>
            <Col>
              <a
                href={"https://www.paperspace.com/console/machines/" + this.state.user["MachineId"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.user["MachineId"]}
              </a>
            </Col>
          </Row>
          <Row>
            <Col xs={2}>Properties:</Col>
            <Col>
                {this.state.user["Properties"] &&
                  this.state.user["Properties"].map(o => (
                    <div key={o.key}>
                      {o.key} = {o.value}
                    </div>
                  ))}
            </Col>
          </Row>

          <h4 className="mt-4">
            <Trans>TITLE_SYSTEM_INFORMATION</Trans>
          </h4>
          {this.state.user["computer"] === null && <div>No computer is yet created</div>}
          {this.state.user["computer"] !== null && (
            <>
              <Row className="mt-3">
                <Col xs={2}>
                  <Trans>LBL_STATUS</Trans>:
                </Col>{" "}
                <Col>
                  <h5>
                    <Badge variant="dark" style={{ textTransform: "uppercase" }}>
                      {this.state.user["computer"]["state"]}
                    </Badge>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <Trans>LBL_NAME</Trans>:
                </Col>{" "}
                <Col>{this.state.user["computer"]["name"]}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <Trans>LBL_OS</Trans>:
                </Col>{" "}
                <Col>{this.state.user["computer"]["os"]}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <Trans>LBL_RAM</Trans>:
                </Col>{" "}
                <Col>{FormatBytes(this.state.user["computer"]["ram"])}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <Trans>LBL_CPU_CORES</Trans>:
                </Col>{" "}
                <Col>{this.state.user["computer"]["cpus"]}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <Trans>LBL_GPU</Trans>:
                </Col>{" "}
                <Col>{this.state.user["computer"]["gpu"]}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <Trans>LBL_STORAGE</Trans>:
                </Col>{" "}
                <Col>
                  {FormatBytes(this.state.user["computer"]["storageUsed"]) +
                    " / " +
                    FormatBytes(this.state.user["computer"]["storageTotal"])}
                </Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <Trans>LBL_PUBLIC_IP</Trans>:
                </Col>{" "}
                <Col>{this.state.user["computer"]["publicIpAddress"]}</Col>
              </Row>
              <Row>
                <Col xs={2}>
                  <Trans>LBL_PRIVATE_IP</Trans>:
                </Col>{" "}
                <Col>{this.state.user["computer"]["privateIpAddress"]}</Col>
              </Row>
              <Row>
                <Col>
                  <div className="mt-5">
                    <PowerManagement user={this.state.user} />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
      );
    } else if (this.state.error) {
      return <ErrorMessage message={this.state.error.toString()} />;
    }

    return null;
  }
}

export default UserDetails;
