import React, { Component } from "react";
import { withRouter } from "react-router";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";

import Navigation from "./Navigation";
import UserForm from "./UserForm";
import UserDetails from "./UserDetails";
import ListUsers from "./ListUsers";
import { ErrorMessage } from "./ErrorMessage";

import config from "../config.js";
import Amplify from "aws-amplify";

Amplify.configure(config.amplify);

class Layout extends Component {
  render() {
    if (this.props.authState === "signedIn" && this.props.authData) {
      const session = this.props.authData.signInUserSession;
      if (
        !("cognito:groups" in session.idToken.payload) ||
        typeof session.idToken.payload["cognito:groups"] === "undefined" ||
        !session.idToken.payload["cognito:groups"].includes("admins")
      )
        return <ErrorMessage message="Not authorized !" />;

      const NavigationWithRouter = withRouter(Navigation);

      return (
        <Router>
          <Container fluid style={{ padding: 0 }}>
            <Row>
              <Col>
                <NavigationWithRouter />
              </Col>
            </Row>
            <Row>
              <Col>
                <Switch>
                  <Route path="/add-user" component={props => <UserForm {...props} />} />
                  <Route path="/edit-user/:id" component={props => <UserForm {...props} />} />
                  <Route path="/user/:id" component={props => <UserDetails {...props} />} />
                  <Route path="/" component={props => <ListUsers {...props} />} />
                </Switch>
              </Col>
            </Row>
          </Container>
        </Router>
      );
    }

    return null;
  }
}

export default Layout;
