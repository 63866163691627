import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Trans } from "react-i18next";
import { API, graphqlOperation } from "aws-amplify";
import SafeMode from "./SafeMode";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

class PowerManagement extends Component {
  state = {
    remoteMachineState: null,
    showSafeMode: false
  };

  watchInterval = null;
  refreshInterval = 5000; // 5 seconds

  _isMounted = false;

  remoteMachineAction = (query, variables) => {
    this.setState({ remoteMachineState: "preparing" });
    API.graphql(graphqlOperation(query, variables)).then(response => {
      if (this._isMounted && response["data"]) {
        this.watchInterval = setInterval(this.refreshState, this.refreshInterval);
      }
    });
  };

  powerOn = () => {
    this.remoteMachineAction(mutations.CloudComputerStart, { id: this.props.user.Id });
  };

  powerOff = () => {
    this.remoteMachineAction(mutations.CloudComputerStop, { id: this.props.user.Id });
  };

  restart = () => {
    this.remoteMachineAction(mutations.CloudComputerRestart, { id: this.props.user.Id });
  };

  refreshState = () => {
    API.graphql(graphqlOperation(queries.CloudComputerInfo, { id: this.props.user.Id })).then(response => {
      if (
        this._isMounted &&
        response["data"]["CloudComputerInfo"]["state"] &&
        response["data"]["CloudComputerInfo"]["state"] !== null
      ) {
        this.setState({ remoteMachineState: response["data"]["CloudComputerInfo"]["state"] });
      }
    });
  };

  handleSafeMode = () => {
    this.setState({ showSafeMode: true });
  };

  componentDidUpdate() {
    if (this.state.remoteMachineState !== null && ["off", "ready"].includes(this.state.remoteMachineState)) {
      clearInterval(this.watchInterval);
      this.watchInterval = null;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (null !== this.watchInterval) {
      clearInterval(this.watchInterval);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.user.computer !== null) {
      this.setState({ remoteMachineState: this.props.user.computer.state });
      if (!["off", "ready"].includes(this.props.user.computer.state)) {
        this.watchInterval = setInterval(this.refreshState, this.refreshInterval);
      }
    }
  }

  render() {
    if (this.props.user.computer === null) return null;
    switch (this.state.remoteMachineState) {
      case "off":
        return (
          <>
            <Button onClick={this.powerOn} variant="primary" size="sm">
              <Trans>LBL_TURN_ON</Trans>
            </Button>
          </>
        );

      case "ready":
        return (
          <>
            <Button onClick={this.powerOff} variant="danger" size="sm">
              <Trans>LBL_TURN_OFF</Trans>
            </Button>{" "}
            <Button onClick={this.restart} variant="primary" size="sm">
              <Trans>LBL_RESTART</Trans>
            </Button>{" "}
            <Button onClick={this.handleSafeMode} variant="info" size="sm">
              <Trans>LBL_SAFE_MODE</Trans>
            </Button>
            {this.state.showSafeMode && <SafeMode user={this.props.user} />}
          </>
        );

      default:
        return (
          <>
            <label className="text-uppercase">{this.state.remoteMachineState}</label>
          </>
        );
    }
  }
}

export default PowerManagement;
