import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import logo from "../assets/rouster-admin.svg";
import iconGroup from "../assets/group.svg";
import iconAddUser from "../assets/add-user.svg";

class Navigation extends Component {
  render() {
    return (
      <Navbar expand="lg" variant="dark">
        <Navbar.Brand as={Link} to="/" style={{ paddingTop: 0, marginRight: 20 }}>
          <img src={logo} height="14" alt="Logo" align="middle" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Item className="mr-1">
              <Nav.Link
                as={Link}
                to="/"
                style={{ minWidth: "120px" }}
                className={this.props.location.pathname === "/" ? "active" : ""}
              >
                <i
                  className="mr-1"
                  style={{
                    verticalAlign: "bottom",
                    width: 32,
                    height: 32,
                    backgroundImage: `url(${iconGroup})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    display: "inline-block"
                  }}
                />
                <Trans>NAV_USERS</Trans>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/add-user"
                style={{ minWidth: "97px" }}
                className={this.props.location.pathname.startsWith("/add-user") ? "active" : ""}
              >
                <i
                  className="mr-1"
                  style={{
                    verticalAlign: "bottom",
                    width: 25,
                    height: 32,
                    backgroundImage: `url(${iconAddUser})`,
                    backgroundPosition: "center",
                    backgroundSize: "20px 20px",
                    backgroundRepeat: "no-repeat",
                    display: "inline-block"
                  }}
                />
                <Trans>NAV_ADD_USER</Trans>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;
