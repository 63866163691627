export const GetUsersInfo = `query GetUsersInfo {
    GetUsersInfo(WithComputerInfo: false) {
        Id
        FName
        LName
        CognitoId
        Email
    }
}`;

export const FindUser = `query FindUser($id: String!, $withComputerInfo: Boolean!) {
    FindUser(Id: $id, WithComputerInfo: $withComputerInfo) {
      Id
      Email
      FName
      LName
      Description
      HWID
      MachineId
      CognitoId
      Properties {
        key
        value
      }
      computer {
        id
        name
        storageTotal
        storageUsed
        storageFree
        dtCreated
        state
        updatesPending
        privateIpAddress
        publicIpAddress
        dtLastRun
        cpus
        gpu
        ram
        os
      }
    }
  }  
`;

export const CloudComputerInfo = `query CloudComputerInfo($id: String!) {
    CloudComputerInfo(Id: $id) {
        state
    }
}`;
