export const RemoveUser = `mutation RemoveUser($id: String!) {
    RemoveUser(Id: $id) {
        Id
    }
}`;

export const AddUser = `mutation AddUser($user: UserInput!) {
    AddUser(User: $user) {
        Id
    }
}`;

export const UpdateUser = `mutation UpdateUser($id: String!, $user: UserInput!) {
    UpdateUser(Id: $id, User: $user) {
        Id
    }
}`;

export const CloudComputerStart = `mutation CloudComputerStart($id: String) {
    CloudComputerStart(Id: $id)
}`;

export const CloudComputerStop = `mutation CloudComputerStop($id: String) {
    CloudComputerStop(Id: $id)
}`;

export const CloudComputerRestart = `mutation CloudComputerRestart($id: String) {
    CloudComputerRestart(Id: $id)
}`;
