export default {
  amplify: {
    Auth: {
      identityPoolId: "eu-central-1:0bb44076-a9cd-4bdf-987c-a644a61fb077",
      region: "eu-central-1",
      userPoolId: "eu-central-1_6MWzJ5Hof",
      userPoolWebClientId: "2uo7083p4rclv27bpoasml2r7s"
    },
    Analytics: {
      disabled: true
    },
    aws_appsync_graphqlEndpoint: "https://dyh5xunzdfaafodiwj73p6dk6e.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-central-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  }
};
