import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { API, graphqlOperation } from "aws-amplify";
import { Spinner } from "./Spinner";
import { ErrorMessage } from "./ErrorMessage";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

//import { Row, Col } from "react-bootstrap";
//import { Redirect } from "react-router-dom";

class UserForm extends Component {
  state = {
    email: "",
    error: null,
    loading: false,
    user: {
      Properties: [
        { key: "virtualhere.ignoredBuses", value: "1-1.1.2,1-1.1.3" },
        { key: "virtualhere.ignoredDevices", value: "424/7800" }
      ]
    }
  };

  loadUser = id => {
    API.graphql(graphqlOperation(queries.FindUser, { id: id, withComputerInfo: false }))
      .then(({ data }) => {
        if (data && "FindUser" in data) {
          delete data.FindUser["computer"];
          this.setState({ loading: false, user: data.FindUser });
        } else {
          throw new Error("Cannot load user data.");
        }
      })
      .catch(error => {
        this.setState({ error: JSON.stringify(error) });
      });
  };

  submitForm = event => {
    this.setState({ loading: true });
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ loading: false });
    }

    if (this.state.user.Id) {
      var user = { ...this.state.user };
      delete user["Id"];
      API.graphql(graphqlOperation(mutations.UpdateUser, { id: this.state.user.Id, user: user }))
        .then(({ data }) => {
          if (!data["UpdateUser"]) {
            throw Error("Error while updating the user.");
          }
        })
        .then(() => {
          this.props.history.push("/");
        })
        .catch(err => {
          this.setState({ error: JSON.stringify(err) });
        });
    } else {
      API.graphql(graphqlOperation(mutations.AddUser, { user: { ...this.state.user } }))
        .then(({ data }) => {
          if (!data["AddUser"]) {
            throw Error("Error while adding new user.");
          }
        })
        .then(() => {
          this.props.history.push("/");
        })
        .catch(err => {
          this.setState({ error: JSON.stringify(err) });
        });
    }
  };

  handleUpdate = input => {
    if (input.value === "") {
      var user = this.state.user;
      delete user[input.id];
      this.setState({ user });
    } else {
      this.setState({ user: { ...this.state.user, [input.id]: input.value } });
    }
  };

  handleUpdateProperty = input => {
    var user = this.state.user;
    if (input.value === "") {
      user.Properties = user.Properties.filter(e => e.key !== input.id);
    } else {
      user.Properties = [
        ...this.state.user.Properties.filter(e => e.key !== input.id),
        { key: input.id, value: input.value }
      ];
    }
    this.setState({ user });
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    if (params.id) {
      this.setState({ loading: true });
      this.loadUser(params.id);
    }
  }

  findUserProperty = key => {
    var found = this.state.user.Properties.find(b => b.key === key);
    if (found) {
      return found.value;
    }
  };

  render() {
    if (this.state.error !== null) return <ErrorMessage message={this.state.error.toString()} />;

    if (this.state.loading)
      return (
        <div className="m-3 text-center">
          <Spinner />
        </div>
      );

    return (
      <div className="row">
        <Form className="box-shadow m-4 col-lg-8 col-md-9 col-sm-10" onSubmit={this.submitForm}>
          <div className="m-4">
            <Form.Row className="mt-4">
              <Form.Text className="text">
                <h2>{!this.state.user.Id && "New"} Rouster User</h2>
              </Form.Text>
            </Form.Row>

            <Form.Group controlId="Email" className="mt-4">
              <Form.Label>E-mail address</Form.Label>
              <Form.Control
                required
                type="email"
                onChange={e => this.handleUpdate(e.target)}
                placeholder="E-mail"
                value={this.state.user.Email}
              />
              <Form.Control.Feedback type="invalid">Please provide a valid e-mail address.</Form.Control.Feedback>
            </Form.Group>

            <Form.Row className="mt-4">
              <Form.Group as={Form.Col} className="col-md-6" controlId="FName">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => this.handleUpdate(e.target)}
                  placeholder="First Name"
                  value={this.state.user.FName}
                />
              </Form.Group>
              <Form.Group as={Form.Col} className="col-md-6" controlId="LName">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => this.handleUpdate(e.target)}
                  placeholder="Last Name"
                  value={this.state.user.LName}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row className="mt-4">
              <Form.Group controlId="Description" className="w-100">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  onChange={e => this.handleUpdate(e.target)}
                  value={this.state.user.Description}
                  rows="3"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row className="mt-4">
              <Form.Group as={Form.Col} className="col-md-6" controlId="HWID">
                <Form.Label>Client Hardware Id</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => this.handleUpdate(e.target)}
                  placeholder="Hardware Id"
                  value={this.state.user.HWID ? this.state.user.HWID : ""}
                />
              </Form.Group>
              <Form.Group as={Form.Col} className="col-md-6" controlId="MachineId">
                <Form.Label>Remote Machine Id</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => this.handleUpdate(e.target)}
                  placeholder="Remote Machine Id"
                  value={this.state.user.MachineId}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row className="mt-4">
              <Form.Group as={Form.Col} className="col-md-6" controlId="parsec.email">
                <Form.Label>Parsec Account E-mail</Form.Label>
                <Form.Control
                  type="email"
                  onChange={e => this.handleUpdateProperty(e.target)}
                  placeholder="Parsec Email"
                  value={this.findUserProperty("parsec.email")}
                />
              </Form.Group>

              <Form.Group as={Form.Col} className="col-md-6" controlId="parsec.pwd">
                <Form.Label>Parsec Account Password</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => this.handleUpdateProperty(e.target)}
                  placeholder="Parsec Password"
                  value={this.findUserProperty("parsec.pwd")}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row className="mt-4">
              <Form.Group as={Form.Col} className="col-md-6" controlId="virtualhere.reverseClients">
                <Form.Label>VirtualHere ReverseClients</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => this.handleUpdateProperty(e.target)}
                  placeholder=""
                  value={this.findUserProperty("virtualhere.reverseClients")}
                />
                <small className="form-text text-muted">
                  You can use the Reverse Clients feature of the Server. This will make the VirtualHere USB Server open
                  a connection to your VirtualHere client, instead of the other way around
                </small>
              </Form.Group>

              <Form.Group as={Form.Col} className="col-md-6" controlId="virtualhere.license">
                <Form.Label>VirtualHere License</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => this.handleUpdateProperty(e.target)}
                  placeholder=""
                  value={this.findUserProperty("virtualhere.license")}
                />
                <small className="form-text text-muted">VirtulHere unique license key</small>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Form.Col} className="col-md-6" controlId="virtualhere.ignoredDevices">
                <Form.Label>VirtualHere IgnoredDevices</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => this.handleUpdateProperty(e.target)}
                  placeholder=""
                  value={this.findUserProperty("virtualhere.ignoredDevices")}
                />
                <small className="form-text text-muted">
                  This specifies the devices to ignore if they are plugged into the server. This setting is of the
                  format <i>xxxx/yyyy,xxxx/yyyy,...</i>
                </small>
              </Form.Group>

              <Form.Group as={Form.Col} className="col-md-6" controlId="virtualhere.ignoredBuses">
                <Form.Label>VirtualHere IgnoredBuses</Form.Label>
                <Form.Control
                  type="text"
                  onChange={e => this.handleUpdateProperty(e.target)}
                  placeholder=""
                  value={this.findUserProperty("virtualhere.ignoredBuses")}
                />
                <small className="form-text text-muted">
                  This specifies the particular USB port(s) to ignore. It is of the format{" "}
                  <i>hostcontroller-hub.port,hostcontroller-hub.port,...</i>
                </small>
              </Form.Group>
            </Form.Row>

            <Button type="submit">Submit</Button>
          </div>
        </Form>
        <pre
          className="box-shadow col-lg-3 col-md-2 col-sm-10 m-4"
          style={{ backgroundColor: "white", fontSize: 14, padding: 10, color: "gray", fontFamily: "monospace" }}
        >
          {JSON.stringify(this.state.user, null, 2)}
        </pre>
      </div>
    );
  }
}

export default UserForm;
