import React from "react";

import Amplify from "aws-amplify";
import { Authenticator, SignUp, ForgotPassword } from "aws-amplify-react";
import config from "./config.js";

import Layout from "./components/Layout";
import { I18n } from "aws-amplify";

Amplify.configure(config.amplify);

const authScreenLabels = {
  en: {
    "Sign in to your account": "Hello Rouster Administrator. Please, sign in to your personal account"
  }
};

I18n.setLanguage("en");
I18n.putVocabularies(authScreenLabels);

class App extends React.Component {
  render() {
    return (
      <Authenticator usernameAttributes="email" includeGreetings={false} hide={[SignUp, ForgotPassword]}>
        <Layout />
      </Authenticator>
    );
  }
}

export default App;
